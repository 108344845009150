import React from 'react';
import { UserContextType } from '.';

import { initialState } from './reducer';

const UserContext = React.createContext<UserContextType>({
  state: initialState,
  getUserProfile: () => null,
  logout: () => null,
  replaceUserInfo: () => null,
});

export default UserContext;
