const BASE_URL = 'https://hflo-backend.herokuapp.com/api/';

// const BASE_URL = 'http://localhost:5000/api/';
const API_CONFIG = {
  REGISTER: `${BASE_URL}auth/register`,
  GET_PROFILE: `${BASE_URL}user/get-profile`,
  SUBMIT_QUESTIONS: `${BASE_URL}question/submit`,
  GET_QUESTIONS: `${BASE_URL}question`,
};

export { API_CONFIG }; 