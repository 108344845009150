import * as React from 'react';
import UserContext from './context';
import reducer, { initialState } from './reducer';
import actions from './actions';
import { Action } from '..';
import {
  UserContextType,
  UserStateType,
  UserType,
} from './types';

type ReducerType = (state: UserStateType, action: Action) => UserStateType;
const cache = (fun: ReducerType) => (state: UserStateType, action: Action) => {
  const cachable = fun(state, action);
  if (cachable.User) {
    localStorage.setItem('User', JSON.stringify(cachable.User));
 }
  return cachable;
};

const UserProvider: React.FC = ({ children }) => {
  const [state, dispatch] = React.useReducer(cache(reducer), initialState);
  React.useEffect(() => {
    const cache1 = localStorage.getItem('User');
    if (cache1) {
      const teller: UserType = JSON.parse(cache1);
     if (teller) {
        dispatch({
          type: 'INIT',
          payload: { data: teller },
        });
      }
    }
  }, []);

  const stateD: Pick<UserContextType, 'state'> = React.useMemo(
    () => ({
      state,
    }),
    [state],
  );

  const dispatches: Omit<UserContextType, 'state'> = React.useMemo(
    () => ({
      getUserProfile: actions.getUserProfile(dispatch),
      logout: actions.logout(dispatch),
      replaceUserInfo: actions.replaceUserInfo(dispatch)
    }),
    [dispatch],
  );

  return (
    <UserContext.Provider value={{ ...stateD, ...dispatches }}>
      {children}
    </UserContext.Provider>
  );
};

export default UserProvider;
