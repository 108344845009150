import { QuestionAction } from "../types";
import {
  QuestionDataType,
  QuestionStateType,
  QuestionType,
  RawQuestionSchema,
} from "./types";
import {
  UPDATE_QUESTIONS,
  ADD_CURRENT,
  SUB_CURRENT,
  SET_ERROR,
  SET_LOADING,
  SET_RESULT_RESPONSE,
  GET_QUESTIONS,
  UPDATE_SHOW,
  RESET,
  INIT,
  UserData,
} from "..";
import { RAWJSONQUESTION } from "../../constants";
import { fetchQuestions } from "../../constants/questions";
import { checkMode, requestHelper, getUserToken } from "../../utils/functions";
import { API_CONFIG } from "../../configs";
import { isResponseType } from "../../types/response.type";

const logError =
  (dispatch: React.Dispatch<QuestionAction>) => (error: string) => {
    dispatch({ type: SET_ERROR, payload: { error } });
  };

const updateQuestions =
  (dispatch: React.Dispatch<QuestionAction>) =>
  async (data: QuestionDataType) => {
    try {
      dispatch({
        type: UPDATE_QUESTIONS,
        payload: { data },
      });
    } catch (e: unknown) {
      const error = e as Error;
    }
  };

const initQuestions =
  (dispatch: React.Dispatch<QuestionAction>) =>
  async (data: QuestionStateType) => {
    console.log(data,"--->data")
    try {
      dispatch({
        type: INIT,
        payload: { data },
      });
    } catch (e: unknown) {
      const error = e as Error;
    }
  };

const updateShow =
  (dispatch: React.Dispatch<QuestionAction>) =>
  async (data: QuestionStateType) => {
    try {
      dispatch({
        type: UPDATE_SHOW,
        payload: {
          data,
        },
      });
    } catch (e: unknown) {
      const error = e as Error;
    }
  };

const resetQuestions =
  (dispatch: React.Dispatch<QuestionAction>) => async () => {
    try {
      dispatch({
        type: RESET,
      });
    } catch (e: unknown) {
      const error = e as Error;
    }
  };

const getQuestions =
  (dispatch: React.Dispatch<QuestionAction>) => async (initData?: UserData) => {
    try {
      const res = await requestHelper(
        API_CONFIG.GET_QUESTIONS + "?limit=100&page=1",
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
          },
        }
      );

      if (res.success) {
        if (isResponseType(res.data)) {
          if (res.data?.success) {
            const quizQuestion = res.data.data as RawQuestionSchema[];
            const quest: QuestionDataType[] = [];
            for (let i = 0; i < quizQuestion.length; i++) {
              switch (quizQuestion[i].type) {
                case 1:
                  quest.push({
                    _id: quizQuestion[i]._id,
                    idx: quizQuestion[i].idx,
                    ratings: quizQuestion[i].ratings,
                    section: quizQuestion[i].section,
                    questionText: quizQuestion[i].questionText,
                    questionSubText: quizQuestion[i].questionSubText,
                    category: quizQuestion[i].category,
                    type: QuestionType.SingleOption,
                    options: quizQuestion[i].options,
                    answers: "",
                  });
                  break;
                case 2:
                  quest.push({
                    _id: quizQuestion[i]._id,
                    idx: quizQuestion[i].idx,
                    ratings: quizQuestion[i].ratings,
                    section: quizQuestion[i].section,
                    questionText: quizQuestion[i].questionText,
                    questionSubText: quizQuestion[i].questionSubText,
                    category: quizQuestion[i].category,
                    type: QuestionType.MultipleOption,
                    options: quizQuestion[i].options,
                    answers: [],
                  });
                  break;
                case 3:
                  quest.push({
                    _id: quizQuestion[i]._id,
                    idx: quizQuestion[i].idx,
                    ratings: quizQuestion[i].ratings,
                    section: quizQuestion[i].section,
                    questionText: quizQuestion[i].questionText,
                    questionSubText: quizQuestion[i].questionSubText,
                    category: quizQuestion[i].category,
                    type: QuestionType.HeightWeight,
                    units: quizQuestion[i].units,
                    currentUnit: {
                      height: quizQuestion[i].units
                        ? quizQuestion[i].units!.height[0]
                        : "",
                      weight: quizQuestion[i].units
                        ? quizQuestion[i].units!.weight[0]
                        : "",
                    },
                    answers: {
                      height: "",
                      weight: "",
                    },
                  });
                  break;
                case 5:
                  quest.push({
                    _id: quizQuestion[i]._id,
                    idx: quizQuestion[i].idx,
                    ratings: quizQuestion[i].ratings,
                    section: quizQuestion[i].section,
                    questionText: quizQuestion[i].questionText,
                    questionSubText: quizQuestion[i].questionSubText,
                    category: quizQuestion[i].category,
                    type: QuestionType.DOB,
                    answers: "",
                  });
                  break;
                case 4:
                  quest.push({
                    _id: quizQuestion[i]._id,
                    idx: quizQuestion[i].idx,
                    ratings: quizQuestion[i].ratings,
                    section: quizQuestion[i].section,
                    questionText: quizQuestion[i].questionText,
                    questionSubText: quizQuestion[i].questionSubText,
                    category: quizQuestion[i].category,
                    type: QuestionType.RangeSlider,
                    answers: 1,
                    range: {
                      min: 1,
                      factor: 1,
                      max: 10,
                    },
                  });
                  break;
                default:
                  break;
              }
            }

            quest.forEach((data) => {
              initData?.quizData.sections.forEach((sData) => {
                sData.answers.forEach((tData) => {
                  if (data._id === tData.questionId) {
                    data.answers = tData.answer;
                  }
                });
              });
            });

            const an: number[] = [];
            quest.forEach((dt) => {
              if (!an.includes(dt.section)) {
                an.push(dt.section);
              }
            });
            const as: {
              sectionId: number;
              start: number;
              end: number;
              questions: QuestionDataType[];
            }[] = [];

            an.forEach((dt) => {
              const init: {
                sectionId: number;
                start: number;
                end: number;
                questions: QuestionDataType[];
              } = {
                sectionId: dt,
                start: 0,
                end: 0,
                questions: [],
              };
              quest.forEach((t) => {
                if (dt === t.section) {
                  init.questions.push(t);
                }
              });
              init.start = init.questions[0].idx;
              init.end = init.questions[init.questions.length - 1].idx;
              as.push(init);
            });

            dispatch({
              type: GET_QUESTIONS,
              payload: {
                data: {
                  currentIdx: 0,
                  submit: false,
                  current: initData
                    ? initData.quizData.current
                    : as[0].sectionId,
                  sections: as,
                  aSections: initData ? initData.quizData.aSections : [1],
                  questions: quest,
                },
              },
            });
          } else {
            logError(dispatch)(res.data.message);
          }
        }
      }

      dispatch({
        type: SET_LOADING,
        payload: {
          loading: false,
        },
      });
    } catch (e: unknown) {
      const error = e as Error;
    }
  };

const subCurrent = (dispatch: React.Dispatch<QuestionAction>) => async () => {
  try {
    dispatch({
      type: SUB_CURRENT,
    });
  } catch (e: unknown) {
    const error = e as Error;
  }
};

export type UserQuizAnswersBody = {
  sectionId: number;
  ratings: number[];
  answers: {
    questionId?: string;
    idx: number;
    answer: string | string[] | number | Record<string, string>;
  }[];
};

const submitQuestion =
  (dispatch: React.Dispatch<QuestionAction>) =>
  async (data: QuestionStateType) => {
    try {
      dispatch({
        type: SET_LOADING,
        payload: {
          loading: true,
        },
      });

      const id = data.sections[data.current - 1].sectionId;
      const arr = data.sections[data.current - 1].questions;
      const rating: number[] = [];

      const tempAns: {
        sectionId: number;
        start: number;
        end: number;
        answers: {
          questionId?: string;
          answer: string | string[] | number | Record<string, string>;
        }[];
      } = {
        sectionId: data.sections[data.current - 1].sectionId,
        start: data.sections[data.current - 1].start,
        end: data.sections[data.current - 1].end,
        answers: [],
      };

      arr.forEach((tt) => {
        tempAns.answers.push({
          questionId: tt._id,
          answer: tt.answers!,
        });
      });

      arr.forEach((dt) => {
        switch (dt.type) {
          case QuestionType.SingleOption:
            Object.entries(dt.ratings).forEach(([key, value]) => {
              if ((value as string[]).includes(dt.answers as string)) {
                rating.push(parseInt(key as string));
              }
            });
            break;
          case QuestionType.MultipleOption:
            const tempR: number[] = [];
            (dt.answers as string[]).forEach((tt) => {
              Object.entries(dt.ratings).forEach(([key, value]) => {
                if ((value as string[]).includes(tt as string)) {
                  tempR.push(parseInt(key as string));
                }
              });
            });

            rating.push(checkMode(tempR));
            break;
          case QuestionType.RangeSlider:
            Object.entries(dt.ratings).forEach(([key, value]) => {
              if ((value as number[]).includes(dt.answers as number)) {
                rating.push(parseInt(key as string));
              }
            });
            break;
        }
      });

      console.log(tempAns);

      const res = await requestHelper(API_CONFIG.SUBMIT_QUESTIONS, {
        method: "POST",
        body: JSON.stringify({
          sectionId: id,
          ratings: rating.filter((e) => e),
          section: tempAns,
        }),
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${getUserToken()}`,
        },
      });

      if (res.success) {
        if (isResponseType(res.data)) {
          if (res.data?.success) {
            const data = res.data.data as {
              showBreak: boolean;
              showResult: boolean;
              data: {
                value: number;
                description: string;
              };
              rootCause?: {
                cause: string;
                description: string;
              };
              lenghtToPainZero?: number;
              next: number;
            };
            dispatch({
              type: SET_RESULT_RESPONSE,
              payload: {
                data,
              },
            });
          } else {
            logError(dispatch)(res.data.message);
          }
        }
      }

      dispatch({
        type: SET_LOADING,
        payload: {
          loading: false,
        },
      });
    } catch (e) {
      const error = e as Error;
    }
  };

const addCurrent = (dispatch: React.Dispatch<QuestionAction>) => async () => {
  try {
    dispatch({
      type: ADD_CURRENT,
    });
  } catch (e: unknown) {
    const error = e as Error;
  }
};

export {
  updateQuestions,
  subCurrent,
  addCurrent,
  getQuestions,
  resetQuestions,
  initQuestions,
  submitQuestion,
  updateShow,
};
