export * from './User';
export * from './types';
export * from "./Questions";
export const INIT = 'INIT';
export const UPDATE_QUESTIONS = 'UPDATE';
export const ADD_CURRENT = 'ADD';
export const SUB_CURRENT = 'SUB';
export const GET_QUESTIONS = 'GET';
export const RESET = 'RESET';
export const UPDATE_SHOW = 'UPDATE_SHOW';
export const SET_USER_PROFILE = 'SET_USER_PROFILE';
export const SET_LOADING = 'SET_LOADING';
export const ADD_USER_INFO = 'ADD_USER_INFO';
export const SET_RESULT_RESPONSE = 'SET_RESULT_RESULTSET_RESULT_RESULT';
export const REPLACE_USER_INFO = 'REPLACE_USER_INFO';
export const SET_ERROR = 'SET_ERROR';
export const LOGOUT = 'LOGOUT';
export const SET_PAGINATION_INFO = "SET_PAGINATION_INFO";







