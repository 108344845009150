import React from 'react';
import { QuestionContextType } from '.';

import { initialState } from './reducer';

const QuestionContext = React.createContext<QuestionContextType>({
  state: initialState,
  initQuestions: ()=> null,
  updateQuestions: () => null,
  addCurrent: () => null,
  subCurrent: () => null,
  getQuestions: () => null,
  resetQuestions: () => null,
  submitQuestion: () => null,
  updateShow: () => null,
});

export default QuestionContext;