import { UserType } from "src/state"; 

export const removeHash = () => {
  window.history.pushState(
    '',
    document.title,
    window.location.pathname + window.location.search,
  );
  window.dispatchEvent(new Event('hashchange'));
};

export const  getNewRangeValue = (value: number,  oldRange:{min: number, max: number},  newRange:{min: number, max: number}) => {
  const rangeOld = oldRange.max - oldRange.min;
  const rangeNew = newRange.max - newRange.min;
  const newValue = (((value - oldRange.min) * rangeNew) / rangeOld) + newRange.min;
  return newValue;
};

export const requestHelper = async (
  url: string,
  args: Record<string, unknown>,
): Promise<{ success: boolean; data: unknown }> => {
  try {
    const response = await window.fetch(url, args);
    const data = await response.json();
    return { success: true, data };
  } catch (err) {
    let error;
    if (err && err instanceof Response) {
      error = await err.json();
    }
    return { success: false, data: error };
  }
};

export const getUserToken = () => {
  return (JSON.parse(localStorage.getItem('User') as string) as UserType).token;
}

export const allEqual = (arr: any[]) => { return arr.every(val => val === arr[0]) };

export const checkMode = (ratings: number[]) => {
        const count: Record<number, any> = {};
        ratings.forEach(e => {
            if (!(e in count)) {
                count[e] = 0;
            }
            count[e]++;
        });
        const arr: number[] = [];
        Object.entries(count).forEach(([k, v]) => {
            arr.push(v as number);
        });

        if (allEqual(arr)) {
            const arr: number[] = [];
            Object.entries(count).forEach(([k, v]) => {
                arr.push(parseInt(k as string));
            });
            return arr.sort((a, b) => b - a)[0];
        }

        let mode = 0;
        let bestCount: number = 0;


        Object.entries(count).forEach(([k, v]) => {
            if (v > bestCount) {
                mode = parseInt(k as string);
                bestCount = v as number;
            }
        });
        return mode;
    }