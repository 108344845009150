import { UserData } from "../User";

export type RawQuestionSchema = {
  _id?: string;
  idx: number;
  questionText: string,
  questionSubText?: string,
  category?: number,
  section: number,
  type: number,
  ratings: Record<string, number[] | string[]>,
  options?: string[],
  range?: {
    min: number,
    max: number,
    factor?: number,
  },
  units?: {
    height: string[],
    weight: string[],
  }
}
export type QuestionDataType = {
  _id?: string;
  idx: number;
  questionText: string,
  questionSubText?: string,
  category?: number,
  section: number,
  type: number,
  ratings: Record<string, number[] | string[]>,
  options?: string[],
  units?: {
    height: string[],
    weight: string[],
  }
  answers?: string[] | string | number | Record<string, string>;
  currentUnit?: {
    height: string,
    weight: string,
  };
  range?: {
    min: number;
    factor?: number;
    max: number;
  },
}

export type UserQuizAnswersBody = {
  sectionId: number,
  ratings: number[],
  current: number,
  currentIdx: number,
  isCompleted: boolean,
  aSections?: number[],
  section: {
    sectionId: number,
    start: number,
    end: number,
    answers: {
        questionId: string,
        answer: string | string[] | number | Record<string, string>
    }[]
  }
}


export type QuestionStateType = {
  loading?: boolean,
  error?: string,
  questions: QuestionDataType[],
  aSections?: number[],
  sections: {
    sectionId: number,
    start: number,
    end: number,
    questions: QuestionDataType[],
  }[],
  result?: {
    showResult?: boolean;
    showBreak?: boolean;
    data?: {
        value: number;
        description: string;
    },
    rootCause?: {
        cause: string;
        description: string;
    };
    lenghtToPainZero?: number;
    next?: number;
},
  current: number,
  currentIdx: number,
  submit: boolean,
}

export type QuestionPayloadType = {
  loading?: boolean;
  error?: string;
  data?: QuestionDataType | QuestionDataType[] | QuestionStateType | {
    showResult: boolean;
    data: {
      value: number;
      description: string;
    };
    rootCause?: {
      cause: string;
      description: string;
    };
    lenghtToPainZero?: number;
    next: number;
  };
};


export enum QuestionType {
  SingleOption = 1,
  MultipleOption,
  HeightWeight,
  RangeSlider,
  DOB,
}

export type QuestionContextType = {
  state: QuestionStateType,
  updateQuestions: (data: QuestionDataType) => void,
  addCurrent: () => void,
  subCurrent: () => void,
  getQuestions: (initData?: UserData) => void,
  resetQuestions: () => void,
  initQuestions: (data: QuestionStateType) => void,
  submitQuestion: (data: QuestionStateType) => void,
  updateShow: (data: QuestionStateType) => void,
}