import { UserStateType } from '.';
import {
  Action,
  SET_LOADING,
  SET_ERROR,
  INIT,
  SET_USER_PROFILE,
  LOGOUT,
  REPLACE_USER_INFO,
} from '..';


export const initialState: UserStateType = {
  loading: false,
  User: undefined,
  error: undefined,
};

const reducer = (
  state: UserStateType = initialState,
  action: Action,
): UserStateType => {
  const current: UserStateType = state;
  const user = action.payload?.data;
  const loading = action.payload?.loading;
  const error = action.payload?.error;

  switch (action.type) {
    case INIT:
      return user
        ? {
            ...current,
           User: user,
          }
        : current;
    case LOGOUT:
      localStorage.removeItem('User');
      return { ...current, User: undefined };
    case SET_ERROR:
      // eslint-disable-next-line no-console
      console.error(error);
      return { ...current, error };
    case SET_LOADING:
      return { ...current, loading };
    case SET_USER_PROFILE:
      return {
        ...current,
        User: current.User ? { ...current.User, ...user } : user,
      };
    case REPLACE_USER_INFO:
      return {
        ...current,
        User: user,
      };
    default:
      return current;
  }
};

export default reducer;
