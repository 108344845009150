import { lazy } from "react";
import { RouteInterface } from "src/types/routes.type";

export const routes: RouteInterface[] = [
  {
    path: "/dashboard",
    component: lazy(() => import("src/pages/dashboard/trial")),
  },
  {
    path: "quiz",
    component: lazy(() => import("../pages/questions")),
  },
  {
    path: "fetchemail",
    component: lazy(() => import("../pages/questions")),
  },
  {
    path: "/email",
    component: lazy(() => import("../pages/email")),
  },
  {
    path: "/break-1",
    component: lazy(() => import("../pages/breaks/break_1")),
  },
  {
    path: "quiz/result-1",
    component: lazy(() => import("../pages/questions/quiz1ResultPage")),
  },
  {
    path: "quiz/result-2",
    component: lazy(() => import("../pages/questions/quiz2ResultPage")),
  },
  {
    path: "quiz/result-3",
    component: lazy(() => import("../pages/questions/quiz3ResultPage")),
  },
  {
    path: "quiz/score",
    component: lazy(() => import("../pages/questions/quiz3ResultPage")),
  },
  {
    path: "*",
    component: lazy(() => import("../pages/404/errorpage")),
  },
  {
    path: "/takeaction/id/:id",
    component: lazy(() => import("src/pages/homepage")),
  },
  {
    path: "/",
    component: lazy(() => import("src/pages/homepage")),
  },
  {
    path: "/quiz/1",
    component: lazy(() => import("src/pages/quizpage.js")),
  },
  {
    path: "/terms",
    component: lazy(() => import("src/pages/terms/terms")),
  },
  {
    path: "/privacy",
    component: lazy(() => import("src/pages/privacy/privacy")),
  },
  {
    path: "/disclaimer",
    component: lazy(() => import("src/pages/disclaimer/disclaimer")),
  },
  {
    path: "/result",
    component: lazy(() => import("src/pages/questions/quiz1ResultPage/second")),
  },
  {
    path: "/res-2",
    component: lazy(() => import("src/pages/questions/quiz2ResultPage/second")),
  },
  {
    path: "/subscription",
    component: lazy(() => import("src/pages/subscription/index")),
  },
];
