import * as React from 'react';
import { Action } from '../types';
import {
  SET_ERROR,
  SET_LOADING,
  SET_USER_PROFILE,
  REPLACE_USER_INFO,
  LOGOUT,
} from '..';
import { requestHelper, getUserToken } from 'src/utils/functions';
import { API_CONFIG } from 'src/configs';
import { isResponseType } from 'src/types/response.type';
import { UserType } from 'src/state';

const logError = (dispatch: React.Dispatch<Action>) => (error: string) => {
  dispatch({ type: SET_ERROR, payload: { error } });
};

const getUserProfile =
  (dispatch: React.Dispatch<Action>) => async () => {
    try {
      dispatch({
        type: SET_LOADING,
        payload: {
          loading: true,
        },
      });
      const res = await requestHelper(API_CONFIG.GET_PROFILE, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${getUserToken()}`
        },
      });
    
      if (res.success) {
        if (isResponseType(res.data)) {
          if (res.data.success) {
             const data = res.data as UserType
            dispatch({
              type: SET_USER_PROFILE, 
              payload: {
                data: {data: {uniqueLink: window.location.origin + '?ref='.concat(data.data?._id as string), ...data.data } , token: getUserToken()} as UserType,
              },
            });
          } else {
            logError(dispatch)('Expired token');
            logout(dispatch)();
          }
        }
      } else {
        logError(dispatch)('Network error');
      }

      dispatch({
        type: SET_LOADING,
        payload: {
          loading: false,
        },
      });
    } catch (e: unknown) {
      const error = e as Error;
      logError(dispatch)(error.message);
      dispatch({
        type: SET_LOADING,
        payload: {
          loading: false,
        },
      });
    }
  };




const logout = (dispatch: React.Dispatch<Action>) => () => {
  try {
    dispatch({
      type: SET_LOADING,
      payload: {
        loading: true,
      },
    });
    dispatch({
      type: LOGOUT,
    });
  } catch (e: unknown) {
    const error = e as Error;
    logError(dispatch)(error.message);
    dispatch({
      type: SET_LOADING,
      payload: {
        loading: false,
      },
    });
  }
}


const replaceUserInfo =
  (dispatch: React.Dispatch<Action>) => async (data: UserType) => {
    try {
      dispatch({
        type: SET_LOADING,
        payload: {
          loading: true,
        },
      });
      dispatch({
        type: REPLACE_USER_INFO,
        payload: { data },
      });
    } catch (e: unknown) {
      const error = e as Error;
      logError(dispatch)(error.message);
      dispatch({
        type: SET_LOADING,
        payload: {
          loading: false,
        },
      });
    }
  };


export default {
  getUserProfile,
  replaceUserInfo,
  logout
};
