import ReactGA from "react-ga";
import { routes } from "src/configs/routes";
import { SnackbarProvider } from "notistack";
import UserProvider from "src/state/User/provider";
import { ErrorHandler } from "src/containers";
import { RouteBuilder } from "./containers/ReactRouterBuilder";
import QuestionsProvider from "./state/Questions/provider";
import Favicon from "react-favicon";
import Logo from "./assets/images/HFlologo.png";
import mixpanel from "mixpanel-browser";

const App = () => {
  const TRACKING_ID = "UA-219325883-1";
  ReactGA.initialize(TRACKING_ID);

  return (
    <SnackbarProvider>
      <div>
        <Favicon url={Logo} />
      </div>
      <UserProvider>
        <QuestionsProvider>
          <RouteBuilder routes={routes} />
        </QuestionsProvider>
      </UserProvider>
    </SnackbarProvider>
  );
};

export default App;
