import * as React from 'react';
import QuestionContext from './context';
import reducer, { initialState } from './reducer';
import { updateQuestions, addCurrent, subCurrent, getQuestions, resetQuestions, updateShow, initQuestions, submitQuestion } from './actions';
import { QuestionAction } from '..';
import {
  QuestionContextType,
  QuestionStateType, 
  QuestionDataType,
} from './types';
import { ReactFCWithChildren } from '../../react';

type ReducerType = (state: QuestionStateType, action: QuestionAction) => QuestionStateType;
const cache = (fun: ReducerType) => (state: QuestionStateType, action: QuestionAction) => {
  const cachable = fun(state, action);
  if (cachable) {
    if(cachable.questions.length !== 0){
      localStorage.setItem('Questions', JSON.stringify(cachable));
    }
 }
  return cachable; 
};

const QuestionsProvider: ReactFCWithChildren = ({children}) => {
  const [state, dispatch] = React.useReducer(cache(reducer), initialState);
  React.useEffect(() => {
    const cache1 = localStorage.getItem('Questions');
    if (cache1) {
      const teller: QuestionStateType = JSON.parse(cache1);
     if (teller) {
        dispatch({
          type: 'INIT',
          payload: { data: teller },
        });
      }
    }
  }, []);

  const stateD: Pick<QuestionContextType, 'state'> = React.useMemo(
    () => ({
      state,
    }),
    [state],
  );

  const dispatches: Omit<QuestionContextType, 'state'> = React.useMemo(
    () => ({
      updateQuestions: updateQuestions(dispatch),
      addCurrent: addCurrent(dispatch),
      subCurrent: subCurrent(dispatch),
      getQuestions: getQuestions(dispatch),
      resetQuestions: resetQuestions(dispatch),
      initQuestions: initQuestions(dispatch),
      submitQuestion: submitQuestion(dispatch),
      updateShow: updateShow(dispatch),
    }),
    [dispatch],
  );

  return (
    <QuestionContext.Provider value={{ ...stateD, ...dispatches }}>
      {children}
    </QuestionContext.Provider>
  );
};

export default QuestionsProvider;