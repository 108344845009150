import styled from 'styled-components';


export const TextField = styled.input`
:focus {
    outline: none !important;
    border:2px solid rgba(85, 44, 98, 0.6);
 }
    -webkit-align-items: center;
     display: -webkit-inline-flex;
     overflow: hidden;
     padding: 10px;
     width: 100%;
     font-size: 14px;
     -moz-appearance: textfield;
     height: 60px;
     border-radius: 8px;
     -webkit-box-sizing: border-box; 
    -moz-box-sizing: border-box;    
    box-sizing: border-box;       
     border: 1.5px solid rgba(85, 44, 98, 0.6);
    -webkit-padding-start: 15px;
`;