import { QuestionStateType, QuestionDataType } from "./types";
import {
  QuestionAction,
  UPDATE_QUESTIONS,
  ADD_CURRENT,
  SUB_CURRENT,
  GET_QUESTIONS,
  SET_LOADING,
  SET_ERROR,
  INIT,
  RESET,
  UPDATE_SHOW,
  SET_RESULT_RESPONSE,
} from "..";

export const initialState: QuestionStateType = {
  questions: [],
  current: 0,
  submit: false,
  sections: [],
  currentIdx: 0,
  aSections: [],
  loading: false,
  error: undefined,
};

const reducer = (
  state: QuestionStateType = initialState,
  action: QuestionAction
): QuestionStateType => {
  // const navigate = useNavigate();
  const current: QuestionStateType = state;
  const data = action.payload?.data as QuestionDataType;
  const index = current.current;
  const loading = action.payload?.loading;
  const error = action.payload?.error;

  switch (action.type) {
    case INIT:
      const datas = action.payload?.data as QuestionStateType;
      return datas ? datas : current;
    case RESET:
      // localStorage.removeItem("Questions");
      // return initialState;
      return { ...current, error };
    case SET_ERROR:
      // eslint-disable-next-line no-console
      console.error(error);
      return { ...current, error };
    case SET_LOADING:
      return { ...current, loading };
    case UPDATE_QUESTIONS:
      current.questions.splice(current.current, 1, data);
      return { ...current };
    case GET_QUESTIONS:
      const qDatas = action.payload?.data as QuestionStateType;
      return qDatas;
    case SET_RESULT_RESPONSE:
      const r = action.payload?.data as {
        showResult: boolean;
        showBreak: boolean;
        data: {
          value: number;
          description: string;
        };
        rootCause?: {
          cause: string;
          description: string;
        };
        lenghtToPainZero?: number;
        next: number;
      };
      if ((current.aSections as number[]).indexOf(r.next) !== -1) {
        current.aSections?.splice(
          (current.aSections as number[]).indexOf(r.next),
          1,
          r.next
        );
      } else {
        (current.aSections as number[]).push(r.next);
      }
      return {
        ...current,
        current: r.next === 0 ? current.current : r.next,
        currentIdx: 0,
        aSections: current.aSections,
        result: r as {
          showResult: boolean;
          showBreak: false;
          data: {
            value: number;
            description: string;
          };
          rootCause?: {
            cause: string;
            description: string;
          };
          lenghtToPainZero?: number;
          next: number;
        },
      };
    case UPDATE_SHOW:
      const d = action.payload?.data as QuestionStateType;
      return d;
    case ADD_CURRENT:
      current.currentIdx = current.currentIdx + 1;
      // if(current.current === (current.questions.length - 1)){
      //   current.current = current.current;
      //   current.submit = true;
      // }else {
      //   current.current = current.current + 1;
      // }

      return { ...current };
    case SUB_CURRENT:
      if (
        current.currentIdx ===
        current.sections[current.current - 1].questions.length - 1
      ) {
        current.currentIdx = current.currentIdx - 1;
      } else {
        if (current.currentIdx === 0 && current.current !== 1) {
          current.current =
            current!.aSections![
              current!.aSections!.indexOf(current.current) - 1
            ];
          current!.aSections!.splice(
            current!.aSections!.indexOf(current.current) + 1,
            1
          );
          current.currentIdx =
            current.sections[current.current - 1].questions.length - 1;
        } else {
          if (current.currentIdx !== 0) {
            current.currentIdx = current.currentIdx - 1;
          }
        }
      }

      return { ...current };
    default:
      return current;
  }
};

export default reducer;
