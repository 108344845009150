export type UserStateType = {
  loading?: boolean;
  error?: string;
  User?: UserType;
};

export type UserContextType = {
  state: UserStateType;
  getUserProfile: () => void;
  replaceUserInfo: (data: UserType) => void;
  logout: () => void;
};

export type UserData = {
  _id: string;
  email: string;
  username?: string;
  firstname?: string;
  referralId?: string;
  identifier?: string;
  referrals: string;
  result: {
    rootCause: {
      cause: string;
      description: string;
    };
    periodPainLevel: number;
    GHILevel: number;
    BSDLevel: number;
    lifeStyleMotivationLevel: number;
    lengthToPainZero: number;
  };
  quizData: {
    current: number;
    isCompleted: boolean;
    aSections: number[];
    sections: {
      sectionId: number,
      start: number,
      end: number,
      answers: {
          questionId: string,
          answer: string | string[] | number | Record<string, string>
      }[]
  }[];
  };
  createdAt?: string;
  updatedAt?: string;
  uniqueLink?: string;
};

export type UserType = {
  data?: UserData;
  token?: string;
};

export type UserPayloadType = {
  loading?: boolean;
  error?: string;
  data?: UserType;
};

export const isUserType = (User: unknown): User is UserType => {
  return (User as UserType).token !== undefined;
};
