import { Column } from "..";
import Stack from "@mui/material/Stack";
import CircularProgress from "@mui/material/CircularProgress";

export const PageLoader = () => {
  return (
    <Column height="100vh" width="100vw" align="center" justify="center">
      <CircularProgress color="secondary" />
    </Column>
  );
};
