import { Theme } from 'src/types';

export const theme: Theme = {
  primary: {
    500: '#552C62',
    400: 'rgba(85, 44, 98, 0.4)',
    200: 'rgba(85, 44, 98, 0.2)',
    100: 'rgba(85, 44, 98, 0.1)',
   },
  blue: {
    100: '#5CB4FF',
  },
  grey: {
    100: '#898989',
  },
  white: {
    100: '#FFFFFF',
  },
  black: {
    100: '#252525',
  },
  orange: {
    100: '#FF9C76',
  },
};

export const font = {
  sizes: {
    base: '1rem',
    xxs: '14px',
    xs: '0.95rem',
    sm: '1.125rem',
    lg: '1.375rem',
    xl: '2rem',
    xxl: '2.875rem',
    xxxl: '3.12rem',
  },
  weights: {
    light: 300,
    regular: 400,
    semibold: 600,
    bold: 700,
  },
};
